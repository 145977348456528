html {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: #01191f;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

#__next {
  overflow-x: hidden;
}
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
input,
button,
textarea {
  font-family: 'Inter', sans-serif;
  padding: 0;
  margin: 0;
}

h1,
h2 {
  font-weight: 500;
}

p {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

* {
  box-sizing: border-box;
}
